import styles from './Card.module.scss';

const Card = ({
  name,
  details,
  url
}: { name: string, details: string[], url?: string }) => {
  const descriptionItems = details.map(item => <li className={styles.descriptionItem} key={item}>{item}</li>);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        {url ?
          <a href={url} className={styles.link}>
            {name}
          </a>
          : <span className={styles.name}>{name}</span>
        }

      </div>
      <div className={styles.cardContent}>
        <ul className={styles.description}>
          {descriptionItems}
        </ul>
      </div>
    </div>
  );
};

export default Card;
