import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Navbar.module.scss';

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);

  const toggleNavMenu = () => setShowLinks(!showLinks)

  return (
    <div className={`${styles.navbar} row`}>
      <button
        className={`${styles.toggleBtn} ${showLinks ? styles.activeBtn : ''}`}
        onClick={toggleNavMenu}
      >
        &#9776;
      </button>
      {
        showLinks ? <div className={styles.menuOverlay} onClick={toggleNavMenu}></div> : ''
      }
      <div className={`${styles.links} ${showLinks ? styles.open : ''}`}>
        <NavLink activeClassName={styles.active} to="/" exact>Home</NavLink>
        <NavLink activeClassName={styles.active} to="/expertise">Expertise</NavLink>
        <NavLink activeClassName={styles.active} to="/experience">Experience</NavLink>
        <NavLink activeClassName={styles.active} to="/contact">Contact</NavLink>
      </div>
    </div>
  );
};

export default Navbar;
