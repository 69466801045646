import styles from './Icon.module.scss';

const Icon = ({ name, logo }: { name: string, logo: string }) => (
  <div className={styles.iconWrapper}>
    <img src={logo} alt={name} className={styles.iconImg} />
    <span className={styles.iconLabel}>{name}</span>
  </div>
);

export default Icon;
