import styles from './Contact.module.scss';

const Contact = () => (
  <div className="content row">
    {/* Animated elements */}
    <div className={`${styles.light} ${styles.x1}`}></div>
    <div className={`${styles.light} ${styles.x2}`}></div>
    <div className={`${styles.light} ${styles.x3}`}></div>
    <div className={`${styles.light} ${styles.x4}`}></div>
    <div className={`${styles.light} ${styles.x5}`}></div>
    <div className={`${styles.light} ${styles.x6}`}></div>
    <div className={`${styles.light} ${styles.x7}`}></div>
    <div className={`${styles.light} ${styles.x8}`}></div>
    <div className={`${styles.light} ${styles.x9}`}></div>
    {/* End of Animated elements */}

    <h1>Contact</h1>
    <ul className={styles.listWrapper}>
      <li>LinkedIn: <a href="https://linkedin.com/in/abelantoniocerda" className="link">linkedin.com/in/abelantoniocerda</a> </li>
      <li>E-mail: <a href="mailto:abelantoniocerda@gmail.com" className="link">abelantoniocerda@gmail.com</a> </li>
      <li>GitHub: <a href="https://github.com/abelcerda" className="link">github.com/abelcerda</a> </li>
    </ul>
  </div>
);

export default Contact;
