import { Link } from 'react-router-dom';
import Card from '../../components/Card/Card';

import styles from './Experience.module.scss';

const experiences = [
  {
    name: 'Openix',
    url: 'https://openix.com.ar/',
    details: [
      'Full-stack developer. Software architect. Project leader.',
      'Technical interviewer and trainer.'
    ]
  },
  {
    name: 'Globant',
    url: 'https://www.globant.com/',
    details: [
      'Senior web developer. Technical leader (interim).',
      'Coordinated teams of 10+ devs, QAs, and BAs.'
    ]
  },
  {
    name: 'Silicon Development',
    url: 'https://www.silicondevelopment.com/',
    details: [
      'Senior software engineer. Full-stack developer.',
      'Integrated financial and investment services from the USA.'
    ]
  },
];

const freelanceExp = {
  name: 'Freelance',
  details: [
    'Full-stack developer. Speaker.',
    'Developed health and government applications, among other industries.'
  ]
};

const Experience = () => {
  const experienceList = experiences.map(exp => (
    <div className={`${styles.cardWrapper} row`} key={exp.name}>
      <Card {...exp} />
    </div>
  ));

  return (
    <div className={`${styles.content} content`}>
      <h1 className="headline row">Experience</h1>
      <p>I worked with these amazing companies...</p>
      <div className={`${styles.companies} row`}>
        {experienceList}
      </div>
      <p>... and a bit of freelancing</p>
      <div className={`${styles.companies} row`}>
        <div className={`${styles.cardWrapper} row`}>
          <Card {...freelanceExp} />
        </div>
      </div>
      <p>
        If you want to know more about my experience, check out the&nbsp;
        <Link to="/contact" className="link">Contact page</Link>
        &nbsp;and send me a message.
      </p>
    </div>
  );
};

export default Experience;
