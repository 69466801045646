import { Switch, Route } from 'react-router-dom';

import './App.scss';
import './assets/styles/shared.scss';

import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Expertise from './pages/Expertise/Expertise';
import Experience from './pages/Experience/Experience';
import Contact from './pages/Contact/Contact';

function App() {
  return (
    <div className="app-root">
      <Navbar />
      <div className="app-content">
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/expertise" >
            <Expertise />
          </Route>
          <Route path="/experience" >
            <Experience />
          </Route>
          <Route path="/contact" >
            <Contact />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
