import { Link } from 'react-router-dom';

import Icon from '../../components/Icon/Icon';
import styles from './Expertise.module.scss';

// FIXME: the logo images should be imported dinamycally, not one by one like this.
import javascriptLogo from '../../assets/img/javascript.png';
import angularLogo from '../../assets/img/angular.png';
import reactLogo from '../../assets/img/react.png';
import nodejsLogo from '../../assets/img/nodejs.png';
import rubyOnRailsLogo from '../../assets/img/ruby_on_rails.png';
import ionicLogo from '../../assets/img/ionic.png';
import reactNativeLogo from '../../assets/img/react_native.png';
import awsLogo from '../../assets/img/aws.png';
import pythonLogo from '../../assets/img/python.png';
import phpLogo from '../../assets/img/php.png';

const mainTechnologies = [
  {
    name: 'Javascript',
    logo: javascriptLogo,
  },
  {
    name: 'Angular',
    logo: angularLogo,
  },
  {
    name: 'React',
    logo: reactLogo,
  },
  {
    name: 'NodeJs',
    logo: nodejsLogo,
  },
  {
    name: 'Ruby on Rails',
    logo: rubyOnRailsLogo,
  }
];

const otherTechnologies = [
  {
    name: 'Ionic',
    logo: ionicLogo,
  },
  {
    name: 'React Native',
    logo: reactNativeLogo,
  },
  {
    name: 'Amazon Web Services',
    logo: awsLogo,
  },
  {
    name: 'Python',
    logo: pythonLogo,
  },
  {
    name: 'PHP',
    logo: phpLogo,
  },
];

const Expertise = () => {
  const mainIcons = mainTechnologies.map(tech => <Icon {...tech} key={tech.name} />);
  const otherIcons = otherTechnologies.map(tech => <Icon {...tech} key={tech.name} />);

  return (
    <div className={`${styles.content} content row`}>
      <h1 className="headeline row">Expertise</h1>
      <p>This is my turf</p>

      <div className={styles.iconListWrapper}>
        {mainIcons}
      </div>

      <p>I also know these...</p>

      <div className={styles.iconListWrapper}>
        {otherIcons}
      </div>

      <p>... among others.</p>

      <p>
        If you want to know more, check out the&nbsp;
        <Link to="/contact" className="link">Contact page</Link>
        &nbsp;and send me a message.
      </p>
    </div>
  );
};

export default Expertise;
