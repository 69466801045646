import styles from './Home.module.scss';

const Home = () => {
  return (
    <div className={styles.content}>

      {/* Animation elements */}
      <div className={styles.bg}></div>
      <div className={`${styles.bg} ${styles.bg2}`}></div>
      <div className={`${styles.bg} ${styles.bg3}`}></div>
      {/* End of animation elements */}

      <h1 className={`${styles.headline} row`}>Abel Antonio Cerda</h1>
      <div className={`${styles.summary} row`}>
        <ul>
          <li>
            Senior software engineer
          </li>
          <li className={styles.divider}>&diams;</li>
          <li>
            Experienced team lead
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
